
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































































































/* stylelint-disable declaration-no-important */
.tv-convenient {
  @extend %text-center;

  position: relative;
  z-index: 1;
  color: $white;

  &.before-enter {
    opacity: 0;
  }

  @include mq($until: m) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @include mq(m) {
    text-align: left;
  }
}

.tv-convenient-inner {
  position: relative;

  @include mq(m) {
    --picture-width: 60%;

    // prettier-ignore
    padding: $spacing * 2 calc(var(--picture-width) + #{$spacing * 4}) $spacing * 2 0;
  }
}

.tv-convenient__title {
  @include mq(m) {
    margin: 0 auto $spacing * 0.5 0;
  }
}

.tv-convenient__intro {
  @include mq($until: m) {
    padding: 0 $spacing * 3;
  }
}

.tv-convenient__picture {
  width: 100%;
  height: fit-content;
  margin: $spacing * 1.5 auto $spacing;

  img {
    display: block;
    width: 100%;
  }

  @include mq(m) {
    @include center-y;

    right: 0;
    width: var(--picture-width);
    height: 100%;
    margin: 0;

    img {
      @include image-fit(contain);
    }
  }
}

.tv-convenient__downloads {
  margin: $spacing auto $spacing * 0.5;

  @include mq(xxs) {
    display: flex;
    justify-content: center;
  }

  @include mq(m) {
    justify-content: flex-start;
    margin-top: $spacing * 2;
  }
}

.tv-convenient__downloads__link {
  display: block;
  transition: opacity 0.3s $ease;

  .icon {
    width: 13rem;
    height: 4rem;
    fill: $white;
  }

  &:hover,
  &:focus-within {
    opacity: 0.7;
  }

  & + & {
    margin-top: $spacing * 0.7;
  }

  @include mq(xxs) {
    & + & {
      margin: 0 0 0 $spacing * 0.7;
    }
  }
}

.tv-convenient__link {
  display: block;
  margin-top: $spacing * 0.5 auto 0;
  color: $white;
}
