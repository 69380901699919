
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



























































.voo-tv-plus {
}

pre {
  margin: $spacing;
  padding: $spacing * 0.5;
  background: $white;
  border: 1px dotted $black;
}

.voo-tv-plus__faq {
  position: relative;
  z-index: 1;
  /* stylelint-disable-next-line declaration-no-important */
  // background-color: $white !important;
}
