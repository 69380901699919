
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































.tv-profits {
  position: relative;
  z-index: 1;
  background-color: $white;
}

.tv-profits-inner {
  display: flex;
  flex-direction: column;

  @include mq(l) {
    flex-direction: row;
    padding: 0;

    > * {
      flex-basis: 50%;
    }
  }
}

.tv-profits__title {
  // prettier-ignore
  @include fluid(font-size,(s: 28px,xl: 54px,));

  // max-width: 30rem;
  margin: 0 auto;
  padding: $spacing * 1.5 $spacing 0;
  font-weight: 400;

  strong {
    font-weight: 700;
  }

  @include mq($until: m) {
    text-align: center;
  }

  @include mq(l) {
    max-width: 80rem;
    padding: $spacing * 5;
  }
}

.tv-profits__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing * 1.5 $spacing;
  background-color: $c-light-gray;

  @include mq($until: l) {
    margin: $spacing;
    border-radius: 1rem;
  }

  @include mq(l) {
    padding: $spacing * 5;
  }
}

.tv-profits__icon {
  width: 3rem;
  height: 3rem;
  margin: $spacing / 2 auto;
  fill: $c-pink-medium;
}

.tv-profits__subtitle {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 12px,xl: 24px,));

  margin: 0;
  font-weight: 400;

  strong {
    font-weight: 700;
    text-align: center;
  }

  ::v-deep {
    p {
      margin-top: 0;
    }
  }
}

.tv-profits__link {
  // @extend %text-center;
}
