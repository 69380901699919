
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































































































































.tv-video-hero {
  @extend %text-center;

  position: relative;
  z-index: 1;
  padding-top: 60vh;
  padding-bottom: $spacing * 2.5;
  color: $white;

  @include mq(l) {
    padding-top: 50vh;
  }
}

.tv-video-hero__logo {
  position: relative;
  z-index: 1;
  display: block;
  width: 14rem;
  margin: 0 auto $spacing;

  @include mq(l) {
    width: 27.5rem;
    margin: 0 auto $spacing * 1.5;
  }
}

.tv-video-hero__title {
  max-width: 18em;
}

.tv-video-hero__video-outer {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 30%,
    left 80%,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );

  video {
    @include image-fit;
  }
}
